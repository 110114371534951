<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-form id="change" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
        关键词：
        <el-input
            style="width: 200px; margin-right: 10px"
            class="search"
            v-model="asearch1"
            prefix-icon="el-icon-search"
            clearable
            placeholder="请输入文章名称"
        />
        <span v-show="isshow()">
          产品发布人：
          <el-input
              style="width: 200px; margin-right: 10px"
              class="search"
              v-model="asearch2"
              prefix-icon="el-icon-search"
              clearable
              placeholder="请输入产品发布人"
          />
        </span>
        产品类型名称：
        <el-select
            v-model="productTypeId"
            placeholder="请选择"
            filterable
            style="width: 200px; margin-right: 10px"
        >
          <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
        <span v-show="isshow()">
        所属客户：
        <el-select
            v-model="customerId"
            placeholder="请选择"
            filterable
            clearable
            style="width: 200px; margin-right: 10px"
        >
          <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </span>
        <el-button
            type="primary"
            @click="getproductList()"
            style="background: #1d90ff; border: none"
        >查询</el-button>
        <el-button type="success" @click="addproduct()" style="background: #1d90ff; border: none">添加产品</el-button>
      </el-form>
    </div>
    <el-table stripe :data="tableData" style="width: 100%; margin-top: 10px" border>
      <el-table-column prop="id" label="编号" width="110" align="center"></el-table-column>
      <el-table-column prop="title" label="标题" width="110" align="center"></el-table-column>
      <el-table-column label="主图" width="120" align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.image"
                :preview-src-list="csrcList(scope.row.image)"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="所属客户" width="110" align="center"></el-table-column>
      <el-table-column prop="nickName" label="产品发布人" width="100" align="center"></el-table-column>
      <el-table-column label="产品类型" prop="productTypeName" width="110" align="center"></el-table-column>
      <el-table-column prop="createdTime" label="开始时间" width="150" align="center"></el-table-column>
      <el-table-column prop="updatedTime" label="更新时间" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" style="background: #1d90ff; border:none"  size="mini" @click="editArticle(scope.row.id)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px;text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagetotal"
      ></el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";
import Cookies from "js-cookie";

export default {
  methods: {
    csrcList(image) {
      const List = [];
      List.push(image);
      List.push(image);
      return List;
    },
    isshow() {
      return this.customeshow;
    },

    getproductList() {
      if (this.userInfo != 0) {
        this.customerId = this.userInfo;
        this.asearch2 = this.cnickName;
      }
      console.log(this.userInfo);
      this.$api.get(
        "/product/list",
        null,
        {
          keyword: this.asearch1,
          nickName: this.asearch2,
          productTypeId: this.productTypeId,
          customerId: this.customerId,
          audit: false,
        },
        (successRes) => {
          console.log(successRes);
          this.tableData = successRes.data.rows;
          var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
              exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;
            this.pagetotal = successRes.data.total;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    getproductType() {
      console.log("getproductType:", this.userInfo);
      if (this.userInfo != 0) {
        this.customerId = this.userInfo;
      }

      this.$api.get(
        "/product/list/type",
        null,
        {
          customerId: this.customerId,
          list: false,
        },
        (successRes) => {
          this.productOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    getcustomer() {
      this.$api.get(
        "/customer/list",
        null,
        {
          list: false,
        },
        (successRes) => {
          this.customerOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    getUserInfoByUserName() {
      console.log(Cookies.get("username"));
      const username = Cookies.get("username");
      this.$api.get(
        "/user/getUserInfoByUserName",
        null,
        {
          username: username,
        },
        (successRes) => {
         
          this.userInfo = successRes.data.customerId;
          this.cnickName = successRes.data.nickName;
          console.log("customerId:", this.userInfo);
          this.getproductList();
          this.getproductType();
          this.getcustomer();
          if(successRes.data.customerId == 0){
            this.customeshow = false;

          }else{
            this.customeshow = true;
          }
          
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },

    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.spagetotal = val;
      this.$api.get(
        "/product/list",
        null,
        {
          page: this.nowpagetotal,
          record: this.spagetotal,
          keyword: this.asearch1,
          nickName: this.asearch2,
          productTypeName: this.asearch3,
          customerId: this.customerId,
        },
        (successRes) => {
          this.pagetotal = successRes.data.total;
          var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
              exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;

          console.log(this.pagetotal);
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );

      console.log(val);
    },
    handleCurrentChange(val) {
      this.nowpagetotal = val;
      this.$api.get(
        "/product/list",
        null,
        {
          page: this.nowpagetotal,
          record: this.spagetotal,
          keyword: this.asearch1,
          nickName: this.asearch2,
          productTypeName: this.asearch3,
          customerId: this.customerId,
        },
        (successRes) => {
          this.pagetotal = successRes.data.total;
           var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
              exf[i].createdTime = exf[i].createdTime.substr(0, 19);
            exf[i].createdTime = new Date(+new Date(exf[i].createdTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].createdTime = exf[i].createdTime.substring(0,10);
            }
            this.tableData = exf;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    addproduct() {
      this.$router.push({ name: "addmodular", params: { userId: 123 } });
    },
    editArticle(id) {

      this.$router.push({ path: 'editmodular',query: {id: id}});
    },
  },
  data() {
    return {
      customeshow: true,
      cnickName: "",
      userInfo: "",
      customerOptions: [],
      customerId: null,
      srcList: [],
      options: [],
      productOptions: [],
      productTypeId: null,
      value: "",
      tableData: [],
      asearch1: "",
      asearch2: "",
      asearch3: "",
      asearchdata: {},

      updateStatus: 0,
      pagetotal: 0,
      spagetotal: "10",
      nowpagetotal: "1",
      currentPage4: 1,
    };
  },
  mounted() {
    this.getUserInfoByUserName();
  },
};
</script>